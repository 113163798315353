import React, { useCallback, useEffect, useState } from "react"
import Layout from "../../components/Layout"
import { githubDB } from "../../db"
import { githubQuery } from "../../query"
import RepoHeader from "../../components/RepoHeader"
import RepoInfo from "../../components/RepoInfo"
import SearchBar from "../../components/SearchBar"
import * as styles from "../../styles/projects.module.css"

function Projects() {
  const [state, setState] = useState({
    name: "",
    repoList: [],
    total: 0,
  })
  const [pageCount, setPageCount] = useState(50)
  const [queryString, setQueryString] = useState("")

  const fetchData = useCallback(() => {
    const queryText = JSON.stringify(githubQuery(pageCount, queryString))
    fetch(githubDB.baseURL, {
      method: "POST",
      headers: githubDB.headers,
      body: queryText,
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        const { name } = data.data.viewer
        const { nodes, repositoryCount } = data.data.search
        setState({
          name: name,
          repoList: nodes,
          total: repositoryCount,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }, [pageCount, queryString])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const { repoList, total } = state

  const projects = repoList && (
    <>
      {repoList.map(list => {
        return <RepoInfo key={list.id} repo={list} />
      })}
    </>
  )

  return (
    <Layout title="Projects">
      <div className={styles.portfolio}>
        <h2>Projects</h2>
        <h3>GitHub Repos + Status</h3>
        <SearchBar
          search={queryString}
          count={pageCount}
          onSearchChange={setQueryString}
          onCountChange={setPageCount}
          total={total}
        />
        <RepoHeader />
        {projects}
      </div>
    </Layout>
  )
}

export default Projects
